import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { ModalBody, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { ArticleHeader2 } from '../../../components/parent/article-header'
import ArticleBGWrapper from '../../../components/parent/article-bg-wrapper'
import UsefulTools from '../../../components/parent/useful-tools'
import {
  CuriosityCloseButton,
  CuriosityModal,
} from '../../../components/parent/curiosity'

import { ArticleWrapper, ArticleContainer } from '../../../components/elements'

const ExpertWrapper = styled.div`
  margin-bottom: 45px;

  .gatsby-image-wrapper {
    height: auto;
    max-width: 125px;
  }

  p {
    font-weight: 400;
    margin-top: 5px;
  }
`

export const Expert = ({ image, name }) => (
  <ExpertWrapper>
    <Img fluid={image} alt={name} />
    <p>{name}</p>
  </ExpertWrapper>
)

const Page = ({ location }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/payback/article1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 1342) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImage: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImageMobile: file(
        relativePath: { eq: "parent/curiosity/MOBILE/mobile_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Expert: file(relativePath: { eq: "parent/payback/expert1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 125) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Czy dawanie kieszonkowego się opłaca? Monika Sajkowska"
        description="Kieszonkowe to nie tylko pieniądze w portfelu czy na koncie dziecka. Kieszonkowe jest elementem wychowania i nauki."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader2
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        title={<>Czy dawanie kieszonkowego się opłaca?</>}
        linkPrev="/strefa-rodzica/kieszonkowe"
        color="#fff"
      />

      <ArticleBGWrapper>
        <ArticleWrapper style={{ marginTop: 0, fontWeight: 300 }}>
          <ArticleContainer>
            <Row>
              <Col xs={12} className="marginTop30">
                <Expert
                  image={data.Expert.childImageSharp.fluid}
                  name="Monika Sajkowska"
                />

                <p>
                  Kieszonkowe to{'\u00A0'}nie tylko pieniądze w{'\u00A0'}
                  portfelu czy na{'\u00A0'}koncie dziecka. Kieszonkowe jest
                  elementem wychowania i{'\u00A0'}nauki. Daje dziecku możliwość
                  zdobycia ważnej wiedzy o{'\u00A0'}świecie – poznania wartości
                  pieniędzy i{'\u00A0'}konsekwencji różnych strategii
                  zarządzania nimi. Buduje w{'\u00A0'}dziecku poczucie, że jest
                  ważne i{'\u00A0'}sprawcze. To{'\u00A0'}również pretekst do
                  {'\u00A0'}rozmowy z{'\u00A0'}dzieckiem o{'\u00A0'}wartościach
                  i{'\u00A0'}miejscu, jakie pieniądze zajmują w{'\u00A0'}ich
                  hierarchii. O{'\u00A0'}tym, że są ważne i{'\u00A0'}dają wiele
                  możliwości, ale nie określają wartości człowieka.
                </p>
                <p>
                  Wysokość i{'\u00A0'}częstotliwość otrzymywania kieszonkowego
                  przez dziecko jest dla wielu rodziców dylematem. Na{'\u00A0'}
                  decyzje rodziców wpływa wiele kryteriów – wiek dziecka, budżet
                  rodziny, styl życia, wysokość kieszonkowego bliskich
                  rówieśników itd. Bardzo pomocne mogą tu{'\u00A0'}być wyniki
                  badań nt. praktyk polskich rodziców i{'\u00A0'}oczekiwań
                  dzieci przeprowadzone w{'\u00A0'}ramach kampanii Misja
                  Kieszonkowe.
                </p>
                <p>
                  Jednak najtrudniejsza jest sytuacja rodzin, których na
                  {'\u00A0'}kieszonkowe dziecka po{'\u00A0'}prostu nie stać.
                  Jeśli dochody rodziny ledwo wystarczają na{'\u00A0'}podstawowe
                  wydatki, to{'\u00A0'}rozterki „ile i{'\u00A0'}jak często”
                  zastępuje pytanie, czy jakiekolwiek kieszonkowe jest możliwe.
                  Jaka jest minimalna kwota, która zasługuje na{'\u00A0'}miano
                  kieszonkowego? Czy można mówić o{'\u00A0'}zarządzaniu
                  pieniędzmi, czy oszczędzaniu, gdy kieszonkowe wynosi 10
                  złotych miesięcznie? Wiadomo, że im{'\u00A0'}mniejsza kwota,
                  tym trudniej realizować cele związane z{'\u00A0'}finansami.
                  Ale rezygnacja z{'\u00A0'}choćby symbolicznego kieszonkowego
                  dla dziecka nie jest dobrym rozwiązaniem. Jeśli wytłumaczymy
                  dziecku rodzinny budżet, zrozumie, dlaczego dostaje do
                  {'\u00A0'}dyspozycji małą kwotę. Może będzie nią rozczarowane,
                  ale doceni, że jest uwzględniane w{'\u00A0'}rodzinnych
                  finansach, że jego potrzeby są ważne, choć nie mogą być w
                  {'\u00A0'}pełni zaspokojone. Nawet drobne kwoty może odkładać,
                  by{'\u00A0'}zrealizować raz na{'\u00A0'}jakiś czas cel, na
                  {'\u00A0'}którego osiągnięcie nie pozwala jednorazowa kwota
                  kieszonkowego. Oczywiście może je{'\u00A0'}też od{'\u00A0'}
                  razu wydać. Ważne, że dziecko ma{'\u00A0'}swój i{'\u00A0'}
                  tylko swój obszar podejmowania decyzji. Jednak gdy ten obszar
                  jest wąski, dziecko może być na{'\u00A0'}różne sposoby
                  wykluczane z{'\u00A0'}grupy rówieśniczej. Zadaniem rodziców w
                  {'\u00A0'}takim przypadku jest umocnić w{'\u00A0'}nim
                  przekonanie, że trudna sytuacja finansowa rodziny to{'\u00A0'}
                  nie wstyd, a{'\u00A0'}wysokie kieszonkowe kolegów, to
                  {'\u00A0'}nie ich zasługa.
                </p>
              </Col>
            </Row>
          </ArticleContainer>
        </ArticleWrapper>

        <UsefulTools
          items={[
            {
              title: 'Ciekawostki',
              subtitle:
                'Rozmowy dzieci z dorosłymi o pieniądzach mają wielką wartość, czyli raport PISA',
              image: data.Tool3,
              onClick: toggleModal,
            },
            {
              title: 'Kolorowanki i rebusy',
              subtitle: 'Kolorowanka',
              image: data.Tool2,
              link: '/parent/Kolorowanka%201.pdf',
            },
            {
              title: 'Gry',
              subtitle: 'Bankosłówka',
              image: data.Tool1,
              link: '/parent/Bankoslowka.pdf',
            },
          ]}
        />
      </ArticleBGWrapper>

      <CuriosityModal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalBody>
          <CuriosityCloseButton onClick={toggleModal} />
          <div />
          <Img
            fluid={data.CuriosityBigImage.childImageSharp.fluid}
            className="curiosityImage"
          />
          <Img
            fluid={data.CuriosityBigImageMobile.childImageSharp.fluid}
            className="curiosityImageMobile"
          />
        </ModalBody>
      </CuriosityModal>
    </Layout>
  )
}

export default Page
